import {CButton, CCard, CCardBody, CFormInput} from "@coreui/react";
import {applyFilters, columns, filterCategories} from "./FilterUtils";
import CIcon from "@coreui/icons-react";
import {cilPlus} from "@coreui/icons";
import DataTable from "react-data-table-component";
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import FilterModal from "./FilterModal";
import {setAlert, deleteEmailRestrictionEntry, getProviderVenueListing, getEmailRestriction} from "../../../../redux/action/action";
import AddModal from "./AddModal";
import Filters from "../../../components/Filters";
import {email_restrictions, users} from "constants";

const BookingRestrictions = () => {
    document.title = "Booking Restrictions | DoubleSpot Business";
    document.querySelector('meta[name="description"]').setAttribute(
        "content",
        "Manage customers' booking privileges with DoubleSpot's business dashboard."
    );

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const venueListing = useSelector(state => state.Apis.providerVenuesListing);
    const userDetail = useSelector(state => state.Apis.userDetail);
    const providerWhitelist = useSelector(state => state.Apis.whitelist);
    const providerDomain = useSelector(state => state.Apis.domain);
    const addedEmailRestrictionEntry = useSelector(state => state.Apis.addedEmailRestrictionEntry);
    const deletedEmailRestrictionEntry = useSelector(state => state.Apis.deletedEmailRestrictionEntry);

    const [venues, setVenues] = useState([]);
    const [whitelist, setWhitelist] = useState([]);
    const [domain, setDomain] = useState([]);
    const [whitelistFilters, setWhitelistFilters] = useState({});
    const [domainFilters, setDomainFilters] = useState({});
    const [whitelistFilterModalVisible, setWhitelistFilterModalVisible] = useState(false);
    const [whitelistFilterCount, setWhitelistFilterCount] = useState(0);
    const [addModalVisible, setAddModalVisible] = useState(false);
    const [editCustomerAccess, setEditEmailRestrictionAccess] = useState(false);
    const [restrictionType, setRestrictionType] = useState(email_restrictions.TYPE_DOMAIN_RESTRICTION);

    const updateWhitelistFilters = (event) => {
        setWhitelistFilters({...whitelistFilters, [event.target.name]: event.target.value});
    };

    const updateDomainFilters = (event) => {
        setDomainFilters({...domainFilters, [event.target.name]: event.target.value});
    };

    const updateWhitelistFiltersMultiselect = (selectedOptions, actionMeta) => {
        setWhitelistFilters({...whitelistFilters, [actionMeta.name]: selectedOptions});
    };

    const onDeleteButtonClick = (id) => {
        if (window.confirm("Are you sure you want to delete the Email Restriction entry?")) {
            dispatch(deleteEmailRestrictionEntry({id, editCustomerAccess}));
        }
    }

    const addDomainOnClick = () => {
        setRestrictionType(email_restrictions.TYPE_DOMAIN_RESTRICTION);
        setAddModalVisible(true);
    }

    const addWhitelistOnClick = () => {
        setRestrictionType(email_restrictions.TYPE_EMAIL_WHITELIST);
        setAddModalVisible(true);
    }

    useEffect(() => {
        dispatch(getProviderVenueListing());
    }, []);

    useEffect(() => {
        if (userDetail?.role) {
            let businessId;
            if (userDetail?.role === users.ROLE_VENUE_OWNER) {
                businessId = userDetail?.id;
            } else if (userDetail.role === users.ROLE_VENUE_MANAGER) {
                businessId = userDetail?.bussnessId;
            }
            dispatch(getEmailRestriction({
                businessId, 
                editCustomerAccess,
                type: email_restrictions.TYPE_EMAIL_WHITELIST,
            }));
            dispatch(getEmailRestriction({
                businessId, 
                editCustomerAccess,
                type: email_restrictions.TYPE_DOMAIN_RESTRICTION,
            }));
        }
        if (addedEmailRestrictionEntry) {
            setAddModalVisible(false);
        }
    }, [userDetail?.id, addedEmailRestrictionEntry, deletedEmailRestrictionEntry, editCustomerAccess]);

    useEffect(() => {
        if (userDetail?.role) {
            if (userDetail.role === users.ROLE_VENUE_OWNER) {
                setEditEmailRestrictionAccess(true);
            } else if (userDetail?.manager?.editCustomerAccess) {
                setEditEmailRestrictionAccess(true);
            } else {
                dispatch(setAlert('You do not have the permissions to view the customers.','error'));
                navigate("/business/reservations/calendar");
            }
        }
    }, [userDetail]);

    useEffect(() => {
        setVenues(venueListing);
    }, [venueListing]);

    useEffect(() => {
        if (providerWhitelist) {
            setWhitelist(applyFilters(providerWhitelist, whitelistFilters));
        }
    }, [providerWhitelist, whitelistFilters]);

    useEffect(() => {
        if (providerDomain) {
            setDomain(applyFilters(providerDomain, domainFilters));
        }
    }, [providerDomain, domainFilters]);

    useEffect(() => {
        let count = 0;
        filterCategories.map(whitelistFilter => {
            if (whitelistFilter.type === 'exact') {
                if (whitelistFilters[whitelistFilter.category]) {
                    count += 1;
                }
            } else if (whitelistFilter.type === 'multiple') {
                if (whitelistFilters[whitelistFilter.category]?.length > 0) {
                    count += 1;
                }
            } else if (whitelistFilter.type === 'range') {
                const [minCategory, maxCategory] = whitelistFilter.category;
                if (whitelistFilters[minCategory] || whitelistFilters[maxCategory]) {
                    count += 1;
                }
            }
        })
        setWhitelistFilterCount(count);
    }, [whitelistFilters]);

    return (
        <div class="p-4 w-100">
            <CCard className="mb-4">
                <CCardBody className="p-4">
                    <div className="flex-row justify-content-between align-items-start">
                        <div>
                            <h2>Organization Domain</h2>
                            <p className="modal-subtitle mb-0">Only customers with the specified email domains will be able 
                                to make reservations.</p>
                        </div>
                        <div>
                            <CButton color="light" onClick={() => addDomainOnClick()}>
                                <CIcon icon={cilPlus} className="primary-color"/> &nbsp; Add Domain Entry
                            </CButton>
                        </div>
                    </div>
                    <hr/>
                    <div class="flex-row flex-wrap align-items-center">
                        <div class="pe-2 pb-3">
                            <CFormInput name="search" placeholder="Search..." onChange={updateDomainFilters}/>
                        </div>
                    </div>
                    <DataTable
                        columns={columns(onDeleteButtonClick, email_restrictions.TYPE_DOMAIN_RESTRICTION)}
                        data={domain}
                        pagination
                    />

                </CCardBody>
            </CCard>
            <CCard>
                <CCardBody className="p-4">
                    <div className="flex-row justify-content-between align-items-start">
                        <div>
                            <h2>Whitelist</h2>
                            <p className="modal-subtitle mb-0">Whitelisted customers' bookings will be automatically
                                confirmed.</p>
                        </div>
                        <div>
                            <CButton color="light" onClick={() => addWhitelistOnClick()}>
                                <CIcon icon={cilPlus} className="primary-color"/> &nbsp; Add Whitelist Entry
                            </CButton>
                        </div>
                    </div>
                    <hr/>
                    <div class="flex-row justify-content-between align-items-start">
                        <Filters
                            filters={whitelistFilters}
                            setFilters={setWhitelistFilters}
                            filterCount={whitelistFilterCount}
                            updateFilters={updateWhitelistFilters}
                            filterCategories={filterCategories}
                            setFilterModalVisible={setWhitelistFilterModalVisible}
                        />
                    </div>
                    <DataTable
                        columns={columns(onDeleteButtonClick, email_restrictions.TYPE_EMAIL_WHITELIST)}
                        data={whitelist}
                        pagination
                    />
                </CCardBody>
            </CCard>
            <FilterModal
                visible={whitelistFilterModalVisible}
                onClose={() => setWhitelistFilterModalVisible(false)}
                updateFilters={updateWhitelistFilters}
                updateFiltersMultiselect={updateWhitelistFiltersMultiselect}
                filters={whitelistFilters}
                venues={venues}
                />
            <AddModal
                visible={addModalVisible}
                onClose={() => setAddModalVisible(false)}
                venues={venues}
                editCustomerAccess={editCustomerAccess}
                type={restrictionType}
                />
        </div>
    )
}

export default BookingRestrictions;
