import React, {useEffect, useState} from "react";
import {
    CBadge,
    CCard,
    CCardBody,
    CDropdown,
    CDropdownDivider,
    CDropdownItem,
    CDropdownMenu,
    CDropdownToggle,
    CTooltip,
} from "@coreui/react";
import DataTable from "react-data-table-component";
import {useDispatch, useSelector} from "react-redux";
import {
    calenderData,
    getEventTags,
    getEventTypes,
    getProviderVenueListing,
    setAlert,
    updateEventDetail,
    updateTask,
} from "../../../../redux/action/action";
import CIcon from "@coreui/icons-react";
import {cilCloudDownload, cilPencil, cilTrash} from "@coreui/icons";
import FilterModal from "./FilterModal";
import {columns, filterCategories, applyFilters} from "./FilterUtils";
import DownloadModal from "./DownloadModal";
import Filters from "../../../components/Filters";
import {bookvenues, users} from "constants";
import EventChecklist from "./EventChecklist";
import EditEventsModal from "./EditEventsModal";

const ReservationTable = () => {
    document.title = "Events List | DoubleSpot Business";
    document.querySelector('meta[name="description"]').setAttribute(
        "content",
        "Organize your venues' booking history with DoubleSpot's business dashboard."
    );

    const dispatch = useDispatch();

    const bookings = useSelector(state => state.Apis.currentBooking);
    const venueListings = useSelector(state => state.Apis.providerVenuesListing);
    const userDetail = useSelector(state => state.Login.userDetail);
    const businessEventTypes = useSelector(state => state.Apis.eventTypes);
    const eventTags = useSelector(state => state.Apis.eventTags);
    const updatedTask = useSelector(state => state.Apis.updatedTask);
    const updatedEvent = useSelector(state => state.Apis.updatedEvent);

    const [userData, setUserData] = useState({});
    const [venues, setVenues] = useState([]);
    const [eventTypes, setEventTypes] = useState([]);
    const [allEventTags, setAllEventTags] = useState([]);
    const [allBookings, setAllBookings] = useState([]);
    const [displayedBookings, setDisplayedBookings] = useState([]);
    const [filters, setFilters] = useState({});
    const [filterModalVisible, setFilterModalVisible] = useState(false);
    const [filterCount, setFilterCount] = useState(0);
    const [downloadModalVisible, setDownloadModalVisible] = useState(false);
    const [selectedRows, setSelectedRows] = useState([]);
    const [toggleCleared, setToggleCleared] = useState(false);
    const [editEventsModalVisible, setEditEventsModalVisible] = useState(false);
    const [editEventsFormData, setEditEventsFormData] = useState({});

    const hasAccess = (accessName) => {
        return userData.role === users.ROLE_VENUE_OWNER || (userData.manager && userData.manager[accessName]);
    }

    const updateFilters = (event) => {
        let newValue = event.target.value;
        if (event.target.name === "any_tags") {
            newValue = event.target.checked;
        }
        setFilters({...filters, [event.target.name]: newValue});
    }

    const updateFiltersMultiselect = (selectedOptions, actionMeta) => {
        setFilters({...filters, [actionMeta.name]: selectedOptions});
    }

    const onTaskStatusChange = (taskId, newStatus) => {
        dispatch(updateTask({
            id: taskId,
            status: newStatus,
        }));
    }

    const editButtonClick = () => {
        const newFormData = {};
        const attributes = [
            "title",
            "eventtype_id",
            "venueId",
            "date",
            "start_time",
            "end_date",
            "end_time",
            "attendees",
            "customer_first_name",
            "customer_last_name",
            "customer_number",
            "customer_email",
            "booked_price",
            "description",
        ];
        for (const attribute of attributes) {
            const allValues = new Set(selectedRows.map(row => row[attribute]));
            if (allValues.size === 1) {
                newFormData[attribute] = selectedRows[0][attribute];
            }
        }
        const allValues = new Set(selectedRows.map(row => JSON.stringify(row.tags)));
        if (allValues.size === 1) {
            newFormData.tags = selectedRows[0].tags.map(tag => ({
                label: tag.name,
                value: tag.id,
            }));
        }
        setEditEventsFormData(newFormData);
        setEditEventsModalVisible(true);
    }

    const deleteButtonClick = () => {
        if (prompt(`You are about to delete ${selectedRows.length} event${selectedRows.length > 1 ? "s" : ""}. If you are sure to continue, please type the number of events to confirm.`) !== selectedRows.length.toString()) {
            dispatch(setAlert("Please acknowledge the number of events before deleting them.", "error"));
            return;
        }
        dispatch(updateEventDetail({
            ids: selectedRows.map(booking => booking.id),
            sendEmail: false,
            deleted: 1,
        }));
    }

    useEffect(() => {
        dispatch(getProviderVenueListing());
    }, []);

    useEffect(() => {
        if (bookings) {
            setAllBookings(bookings);
        }
    }, [bookings]);

    useEffect(() => {
        setDisplayedBookings(applyFilters(allBookings, filters, hasAccess("acceptBookingAccess")));
    }, [allBookings, filters]);

    useEffect(() => {
        dispatch(calenderData({page: 1, venueId: false}));
        dispatch(getEventTags());
        setEditEventsModalVisible(false);
        setToggleCleared(!toggleCleared);
        setSelectedRows([]);
    }, [updatedEvent]);

    useEffect(() => {
        dispatch(calenderData({page: 1, venueId: false}));
    }, [updatedTask]);

    useEffect(() => {
        setAllEventTags(eventTags);
    }, [eventTags]);

    useEffect(() => {
        setVenues([
            {
                id: 0,
                name: "No Change",
            },
            ...venueListings
        ]);
    }, [venueListings]);

    useEffect(() => {
        if (userDetail?.role) {
            let businessId;
            if (userDetail?.role === users.ROLE_VENUE_OWNER) {
                businessId = userDetail?.id;
            } else if (userDetail.role === users.ROLE_VENUE_MANAGER) {
                businessId = userDetail?.bussnessId;
            }
            dispatch(getEventTypes({
                businessId
            }));
        }
    }, [userDetail?.id]);

    useEffect(() => {
        setUserData(userDetail);
    }, [userDetail]);

    useEffect(() => {
        setEventTypes([
            {
                id: 0,
                name: "No Change",
            },
            ...businessEventTypes
        ]);
    }, [businessEventTypes]);

    useEffect(() => {
        let count = 0;
        filterCategories.map(filter => {
            if (filter.type === 'exact') {
                if (filters[filter.category]) {
                    count += 1;
                }
            } else if (filter.type === 'multiple') {
                if (filters[filter.category]?.length > 0) {
                    count += 1;
                }
            } else if (filter.type === 'range') {
                const [minCategory, maxCategory] = filter.category;
                if (filters[minCategory] || filters[maxCategory]) {
                    count += 1;
                }
            }
        })
        setFilterCount(count);
    }, [filters]);

    return (
        <div class="p-4 w-100">
            <CCard>
                <CCardBody className="p-4">
                    <h2>Events</h2>
                    <hr/>
                    <div class="flex-row justify-content-between align-items-start">
                        <Filters
                            filters={filters}
                            setFilters={setFilters}
                            filterCount={filterCount}
                            updateFilters={updateFilters}
                            filterCategories={filterCategories}
                            setFilterModalVisible={setFilterModalVisible}
                        />
                        <div>
                            <CDropdown>
                                <CDropdownToggle color="light" disabled={selectedRows.length === 0}>
                                    Actions <CBadge color="secondary">{selectedRows.length}</CBadge>&nbsp;
                                </CDropdownToggle>
                                <CDropdownMenu>
                                    {
                                        hasAccess("editEventAccess") ?
                                            selectedRows.find(row => row.status !== bookvenues.STATUS_INTERNAL) !== undefined ?
                                                <CTooltip content="Only internal events can be edited in bulk.">
                                                    <span tabIndex={0}>
                                                        <CDropdownItem role="button" disabled>
                                                            <CIcon icon={cilPencil} className="primary-color"/> &nbsp; Edit Events
                                                        </CDropdownItem>
                                                    </span>
                                                </CTooltip>
                                                :
                                                <CDropdownItem role="button" onClick={editButtonClick}>
                                                    <CIcon icon={cilPencil} className="primary-color"/> &nbsp; Edit Events
                                                </CDropdownItem> : null
                                    }
                                    <CDropdownItem role="button" onClick={() => setDownloadModalVisible(true)}>
                                        <CIcon icon={cilCloudDownload} className="primary-color"/>&nbsp; Generate Report
                                    </CDropdownItem>
                                    {
                                        hasAccess("editEventAccess") ?
                                            <>
                                                <CDropdownDivider/>
                                                {
                                                    selectedRows.find(row => row.status !== bookvenues.STATUS_INTERNAL) !== undefined ?
                                                        <CTooltip content="Only internal events can be deleted in bulk.">
                                                            <span tabIndex={0}>
                                                                <CDropdownItem role="button" disabled>
                                                                    <CIcon icon={cilTrash}
                                                                           className="primary-color"/> &nbsp; Delete Events
                                                                </CDropdownItem>
                                                            </span>
                                                        </CTooltip>
                                                        :
                                                        <CDropdownItem role="button" onClick={deleteButtonClick}>
                                                            <CIcon icon={cilTrash} className="primary-color"/> &nbsp; Delete
                                                            Events
                                                        </CDropdownItem>
                                                }
                                            </> : null
                                    }
                                </CDropdownMenu>
                            </CDropdown>
                        </div>
                    </div>
                    <DataTable
                        columns={columns}
                        data={displayedBookings}
                        selectableRows
                        expandableRows
                        expandableRowsComponent={row => <EventChecklist
                            data={row.data}
                            onTaskStatusChange={onTaskStatusChange}
                            dummyProp="dummy"
                        />}
                        expandableRowDisabled={row => (row?.tasks || []).length === 0}
                        onSelectedRowsChange={(selected) => setSelectedRows(selected.selectedRows)}
                        clearSelectedRows={toggleCleared}
                        pagination
                    />
                </CCardBody>
            </CCard>
            <FilterModal
                visible={filterModalVisible}
                onClose={() => setFilterModalVisible(false)}
                updateFilters={updateFilters}
                updateFiltersMultiselect={updateFiltersMultiselect}
                filters={filters}
                venues={venues}
                eventTypes={eventTypes}
                eventTags={allEventTags}
                acceptBookingAccess={hasAccess("acceptBookingAccess")}
            />
            <DownloadModal
                visible={downloadModalVisible}
                onClose={() => setDownloadModalVisible(false)}
                selectedRows={selectedRows}
            />
            <EditEventsModal
                visible={editEventsModalVisible}
                onClose={() => setEditEventsModalVisible(false)}
                editEventsFormData={editEventsFormData}
                setEditEventsFormData={setEditEventsFormData}
                dispatch={dispatch}
                bookings={allBookings}
                eventTypes={eventTypes}
                eventTags={allEventTags}
                setEventTags={setAllEventTags}
                venues={venues}
                selectedBookings={selectedRows}
            />
        </div>
    );
}

export default ReservationTable;
