import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {getProviderVenueListing, setAlert} from "../../../../redux/action/action";
import {
    CCard,
    CCardBody,
    CFormInput,
} from "@coreui/react";
import {useNavigate} from "react-router-dom";
import {users} from "constants";
import DataTable from "react-data-table-component";
import {columns} from "./FilterUtils";

const Venues = () => {
    document.title = "Venues | DoubleSpot Business";
    document.querySelector('meta[name="description"]').setAttribute(
        "content",
        "View and manage your event venue details with DoubleSpot's business dashboard."
    );

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const userDetail = useSelector(state => state.Apis.userDetail);
    const venueListing = useSelector(state => state.Apis.providerVenuesListing);

    const [displayedVenues, setDisplayedVenues] = useState([]);
    const [search, setSearch] = useState("");

    useEffect(() => {
        dispatch(getProviderVenueListing())
    }, [])


    useEffect(() => {
        let newDisplayedVenues = venueListing;
        if (search) {
            const searchTerm = search.toLowerCase();
            newDisplayedVenues = newDisplayedVenues.filter(entry => {
                if (entry?.name.toLowerCase().includes(searchTerm)) return true;
                if (entry?.location.toLowerCase().includes(searchTerm)) return true;
                return false;
            });
        }
        setDisplayedVenues(newDisplayedVenues);
    }, [venueListing, search]);

    useEffect(() => {
        if (userDetail.role) {
            if (userDetail.role !== users.ROLE_VENUE_OWNER && !userDetail.manager?.editVenueAccess) {
                dispatch(setAlert('You do not have the permissions to view the venues.','error'));
                navigate("/business/reservations/calendar");
            }
        }
    }, [userDetail]);

    return (
        <div class="p-4 w-100">
            <CCard>
                <CCardBody className="p-4">
                    <h2>Venues</h2>
                    <hr/>
                    <div class="flex-row flex-wrap align-items-center">
                        <div class="pe-2 pb-3">
                            <CFormInput placeholder="Search..." onChange={
                                (event) => setSearch(event.target.value)
                            }/>
                        </div>
                    </div>
                    <DataTable
                        columns={columns}
                        data={displayedVenues}
                        pagination
                    />
                </CCardBody>
            </CCard>
        </div>
    );
}

export default Venues;
