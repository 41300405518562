import {CBadge, CProgress, CProgressBar} from "@coreui/react";
import {formatDate} from "../../../../Utils/DateTimeUtils";
import {bookvenues} from "constants";
import {getTaskCompletionPercentage} from "../../../../Utils/TaskUtils";
import ProgressBar from "../../../components/ProgressBar";

export const columns = [
    {
        name: 'Title',
        selector: row => row.title,
        cell: row => <a className="flex-row align-items-center external-link" href={`/business/events/${row.id}`} target="_blank">{row.title}</a>,
        width: "200px",
        wrap: true,
        sortable: true,
        reorder: true,
    },
    {
        name: 'Status',
        selector: row => row.status,
        cell: row => {
            switch (row.status) {
                case bookvenues.STATUS_CONFIRMED:
                    return <CBadge color="success">Confirmed</CBadge>;
                case bookvenues.STATUS_PENDING:
                    return <CBadge color="danger">Pending</CBadge>;
                case bookvenues.STATUS_INTERNAL:
                    return <CBadge textBgColor="light">Internal</CBadge>;
            }
        },
        width: "100px",
        sortable: true,
        reorder: true,
    },
    {
        name: "Task Completion",
        selector: row => getTaskCompletionPercentage(row.tasks),
        cell: row => {
            const percentage = getTaskCompletionPercentage(row.tasks);
            return percentage === null ? "N/A" : <ProgressBar percentage={percentage} className="w-100"/>
        },
        width: "150px",
        sortable: true,
        reorder: true,
    },
    {
        name: 'Venue',
        selector: row => row.venue_name,
        width: "200px",
        wrap: true,
        sortable: true,
        reorder: true,
    },
    {
        name: 'Start Time',
        selector: row => new Date(`${row.date} ${row.start_time}`),
        format: row => formatDate(row.date, row.start_time),
        width: "300px",
        sortable: true,
        reorder: true,
    },
    {
        name: 'End Time',
        selector: row => new Date(`${row.end_date} ${row.end_time}`),
        format: row => formatDate(row.end_date, row.end_time),
        width: "300px",
        sortable: true,
        reorder: true,
    },
    {
        name: 'Event Type',
        selector: row => row.eventtype_name,
        cell: row => (
            <CBadge class={`badge calendar-event-color-${row.eventtype_color ? row.eventtype_color : "gray"}`}>
                {row.eventtype_name || "Other"}
            </CBadge>
        ),
        width: "150px",
        sortable: true,
        reorder: true,
    },
    {
        name: 'Tags',
        selector: row => row.tags,
        cell: row => (
            <div className="flex-row flex-wrap row-gap-1 py-3">
                {
                    row.tags?.map(
                        tag => (
                            <div className="px-1">
                                <h6 class="mb-0"><CBadge className="black" color="light">{tag.name}</CBadge></h6>
                            </div>
                        )
                    )
                }
            </div>
        ),
        width: "300px",
        wrap: true,
        reorder: true,
    },
    {
        name: 'Attendees',
        selector: row => row.attendees,
        width: "125px",
        sortable: true,
        reorder: true,
    },
    {
        name: 'Revenue',
        selector: row => row.booked_price,
        format: row => `$ ${row.booked_price}`,
        width: "100px",
        sortable: true,
        reorder: true,
    },
    {
        name: 'Confirmation No.',
        selector: row => row.confirmation_no ? row.confirmation_no : "N/A",
        width: "150px",
        sortable: true,
        reorder: true,
    },
    {
        name: 'Customer',
        selector: row => {
            const name = `${row.customer_first_name} ${row.customer_last_name}`;
            return !name || name === " " ? "N/A" : name;
        },
        width: "200px",
        wrap: true,
        sortable: true,
        reorder: true,
    },
    {
        name: 'Customer Phone',
        selector: row => row.customer_number || "N/A",
        width: "150px",
        sortable: true,
        reorder: true,
    },
    {
        name: 'Customer Email',
        selector: row => row.customer_email || "N/A",
        width: "250px",
        wrap: true,
        sortable: true,
        reorder: true,
    },
    {
        name: 'Description',
        selector: row => row.customer_message || "N/A",
        cell: row => <span class="py-2 display-line-break">{row.customer_message || "N/A"}</span>,
        width: "300px",
        wrap: true,
        sortable: true,
        reorder: true,
    },
];

export const filterCategories = [
    {
        name: 'Status',
        category: 'statuses',
        type: 'multiple',
    },
    {
        name: 'Venues',
        category: 'venues',
        type: 'multiple',
    },
    {
        name: 'Date',
        category: ['start_date', 'end_date'],
        type: 'range',
    },
    {
        name: 'Event type',
        category: 'event_types',
        type: 'multiple',
    },
    {
        name: 'Tags',
        category: 'tags',
        type: 'multiple',
    },
    {
        name: 'Attendees',
        category: ['min_attendees', 'max_attendees'],
        type: 'range',
    },
    {
        name: 'Revenue',
        category: ['min_revenue', 'max_revenue'],
        type: 'range',
    },
    {
        name: 'Customer Name',
        category: 'customer_name',
        type: 'exact',
    },
    {
        name: 'Customer Phone',
        category: 'customer_phone',
        type: 'exact',
    },
    {
        name: 'Customer Email',
        category: 'customer_email',
        type: 'exact',
    },
];

export const applyFilters = (bookings, filters, acceptBookingAccess) => {
    return bookings.filter(booking => {
        const lowerCustomerName = `${booking.customer_first_name} ${booking.customer_last_name}`.toLowerCase();

        if (filters.search) {
            const searchTerm = filters.search.toLowerCase();
            if (!(
                booking.title.toLowerCase().includes(searchTerm) ||
                booking.confirmation_no?.toLowerCase().includes(searchTerm) ||
                booking.customer_message?.toLowerCase().includes(searchTerm) ||
                lowerCustomerName.includes(searchTerm)
            )) {
                return false;
            }
        }

        if (!acceptBookingAccess){
            if (booking.status === 2) return false;
        }

        if (filters.statuses?.length) {
            if (!filters.statuses.map(status => status.value).includes(booking.status)) return false;
        }

        if (filters.venues?.length) {
            if (!filters.venues.map(venue => venue.value).includes(booking.venueId)) return false;
        }

        if (filters.start_date) {
            if (booking.end_date < filters.start_date) return false;
        }

        if (filters.end_date) {
            if (booking.date > filters.end_date) return false;
        }

        if (filters.event_types?.length) {
            if (!filters.event_types.map(event_type => event_type.value).includes(booking.eventtype_id)) return false;
        }

        if (filters.tags?.length) {
            const filterTagIds = new Set(filters.tags.map(tag => tag.value));
            const eventTagIds = new Set(booking.tags ? booking.tags.map(tag => tag.id) : []);
            if (filters.any_tags) {
                if (!filterTagIds.intersection(eventTagIds).size) return false;
            } else {
                if (filterTagIds.intersection(eventTagIds).size !== filterTagIds.size) return false;
            }
        }

        if (filters.min_attendees) {
            if (booking.attendees < filters.min_attendees) return false;
        }

        if (filters.max_attendees) {
            if (booking.attendees > filters.max_attendees) return false;
        }

        if (filters.min_revenue) {
            if (booking.booked_price < filters.min_revenue) return false;
        }

        if (filters.max_revenue) {
            if (booking.booked_price > filters.max_revenue) return false;
        }

        if (filters.customer_name) {
            const searchTerm = filters.customer_name.toLowerCase();
            if (!lowerCustomerName.includes(searchTerm)) return false;
        }

        if (filters.customer_phone) {
            const searchTerm = filters.customer_phone.toLowerCase();
            if (!booking.customer_number.includes(searchTerm)) return false;
        }

        if (filters.customer_email) {
            const searchTerm = filters.customer_email.toLowerCase();
            if (!booking.customer_email.toLowerCase().includes(searchTerm)) return false;
        }

        return true;
    });
};
