import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import SignIn from "./SignIn";
import { useState } from "react";
import SignUp from "./SignUp";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import {
    logout,
    getNotification,
    getUserProfile,
} from "../../redux/action/action";
import { closeModal } from "../../redux/action/accountModals";
import { useDispatch } from "react-redux";
import ForgotPassword from "./ForgotPassword";
import { getProfilePictureUri } from "../../Utils/UriUtils";


const Navbar = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const location = useLocation()

    const openSignIn = useSelector(state => state.HandleModals.openSignInModal);
    const openSignUp = useSelector(state => state.HandleModals.openSignUpModal);
    const openForgot = useSelector(state => state.HandleModals.openForgotModal);


    useEffect(() => {
        console.log(openSignIn, openSignUp, openForgot)
    }, [openSignIn, openSignUp, openForgot])

    const { name, verify_account } = useSelector(state => state.Login.userDetail);
    const suggestionVenue = useSelector(state => state.Apis.searchVenueInNavBar);
    const userDetial = useSelector(state => state.Login.userDetail);
    const [showNavBar, setShowNavBar] = useState(false)
    const [openUserProfile, setOpenUserProfile] = useState(false)

    const [userRole, setUserRole] = useState(null)
    const [pathName, setPathName] = useState('')
    const [verify, setVrify] = useState(null)
    const [openMinNavBar, setOpenMinNavBar] = useState(false)
    const [showSearchBar, setShowSearchBar] = useState(false)
    const [showNotifyUser, setShowNotifyUser] = useState(false)
    const [showNotifyProvider, setShowNotifyProvider] = useState(false)
    const [searhVenue, setSeachVenue] = useState([])
    const handleSeachBar = () => setShowSearchBar(true)
    const handleSeachBarfalse = () => setShowSearchBar(false)
    const handleNavbar = () => setOpenMinNavBar(!openMinNavBar)
    const [userData , setUserData] = useState('')


    function showUserNotification() {
        setShowNotifyUser(!showNotifyUser)
        setShowNotifyProvider(false)
    }

    // useEffect(() => {
    //     return () => {
    //         if (socket && !localStorage.getItem('token') && !currentId) {
    //             console.log("disconnect")
    //             socket.disconnect();
    //             console.log('disconnect successfully===============================');
    //         }
    //     }
    // }, [socket, currentId])

    //check the user login or not
    useEffect(() => {
        setUserRole(localStorage.getItem("userRole"))
        setVrify(localStorage.getItem("va"))

    }, [userRole, verify_account, name]);

    useEffect(() => {
        if(userRole == 1){
            dispatch(closeModal())
        }

    }, [userRole]);

    function logoutUser() {
        localStorage.clear()
        navigate('/')
        setUserRole(null)
        setOpenUserProfile(false)
        setOpenMinNavBar(false)
        setShowNavBar(false)
        // console.log(loginUserDetail)
        dispatch(logout())

    }

    //function to toggle userprofile content
    const changeUserProfile = () => {
        setOpenUserProfile(!openUserProfile)
        // localStorage.removeItem("venue_info")
        // localStorage.removeItem("isBooked")
    }

    useEffect(() => {
        const path = location.pathname.split("/")
        if (path.length >= 1 && path[1] != "") {
            setPathName(path[1])
        } else {
            setPathName("")
        }
    })

    useEffect(() => {
        // searchVenueByName
        setSeachVenue(suggestionVenue)

    }, [suggestionVenue])

    //for the notification
    const notification = useSelector(state => state.Apis.notificatioList)

    const [data, setData] = useState([])
    // notificatioList
    useEffect(() => {
        setData(notification)
    }, [notification])

    useEffect(() => {
        dispatch(getNotification())
    }, [])

    //@des - check the user detail 
    useEffect(() => {
        setUserData(userDetial)
    }, [userDetial])

    useEffect(() => {
        dispatch(getUserProfile())
    }, [])

    function hideOnClickOutside(id){
        const outsideClickListener = (event) => {
            const dropdown = document.getElementById(id)
            const isClickInside = dropdown?.contains(event.target)
            if (!isClickInside) {
                removeClickListener()
                setOpenUserProfile(false)
            }
        }
        const removeClickListener = () => {
            document.removeEventListener('click', outsideClickListener);
        }
        document.addEventListener('click',  outsideClickListener)
    }

    if (userRole === "1") {
        return <>
        {/* {console.log("check the user data ==================>>>" , userData.profile_image)} */}
            <nav className="navbar  navbar-expand-xl bg-white" onMouseLeave={() => handleSeachBarfalse()}>
            
                <div className="container-fluid">
                    <a className="navbar-brand" ><img src='../../../assets/images/logo.png' alt="doublespot.com " /></a>
                    
                    <div id="dropdown1" className="btn-group pro_drop d-xl-none ms-auto me-2 ">
                        <img onClick={(e) => {changeUserProfile(); hideOnClickOutside('dropdown1')}}
                            src={ getProfilePictureUri(userData?.profile_image, "../../../assets")} 
                            data-bs-toggle="dropdown"
                            aria-expanded="true"
                            className="nav_user_img rounded-circle" />

                        <div className={openUserProfile ? "dropdown-menu dropdown-menu-end show" : "dropdown-menu dropdown-menu-end"}>
                            <Link onClick={() => changeUserProfile()} to={'/profile'}><a className="text_icon text-decoration-none text-black">
                                <img src="../../../assets/icons/profile.png" alt="DoubleSpot.com" /> Profile
                            </a></Link>
                            {/* <Link to={'/chat'} onClick={() => changeUserProfile()}><a className="text_icon text-decoration-none text-black">
                                <img src="../../../assets/images/messages.png" alt="DoubleSpot.com" /> Messages
                            </a></Link> */}
                            {/* <Link to={'/notification'} onClick={() => changeUserProfile()}><a className="text_icon text-decoration-none text-black">
                                <img src="../../../assets/images/ball.png" alt="DoubleSpot.com" /> Notifications
                            </a></Link> */}
                            <Link to={'/booking'} onClick={() => changeUserProfile()}><a className="text_icon text-decoration-none text-black">
                                <img src="../../../assets/icons/bookings.png" alt="DoubleSpot.com" /> Bookings
                            </a></Link>
                            <Link to={'/wishlist'} onClick={() => changeUserProfile()}><a className="text_icon text-decoration-none text-black">
                                <img src="../../../assets/icons/wishlist.png" alt="DoubleSpot.com" /> Wishlists
                            </a></Link>
                            <Link to={'/profile'} onClick={() => changeUserProfile()}><a href="help.html" className="pro_btn d-none bg-danger">Help Center</a></Link>
                            <li className="pro_btn" onClick={() => logoutUser()} >Logout</li>

                        </div>
                    </div>

                    <button className="navbar-toggler" type="button" onClick={() => handleNavbar()}>
                        <span className="navbar-toggler-icon fs-6"></span>
                    </button>

                    <div className={openMinNavBar ? "collapse navbar-collapse show" : "collapse navbar-collapse"} id="navbarSupportedContent">
                        <ul className="navbar-nav w-100 mb-lg-0 align-itmes-center">
                            {/* <li className="nav-item d_filter">
                                <form className="dropdown_filter ">
                                    <div className="dropdown filter">
                                        <div className="form-group dropbtn dropdown-open">
                                            <i className="fa-solid fa-magnifying-glass"></i>
                                            <input type="button" onMouseEnter={() => handleSeachBar()} className="form-control" value="Search" />
                                        </div>
                                        <div id="myDropdown" className="dropdown-content dropdown-inner" style={{ display: showSearchBar ? "block" : "none" }}>
                                            <div className="form-group">
                                                <i className="fa-solid fa-magnifying-glass dropdown-open"
                                                ></i>
                                                <input type="text" onChange={(e) => seachData(e.target.value)} className="form-control w-100 rounded-0"
                                                    placeholder="Find a venues" id="myInput" />
                                            </div>
                                            <ul className=" inner_links list-unstyled" id="myUL">
                                                {
                                                    searhVenue && searhVenue.length > 0 ?

                                                        searhVenue.map((val, key) => {
                                                            return <>
                                                                <li key={key} onClick={() => localStorage.setItem("venueId", val.id)} ><Link to={'/venue'}>{val.name}</Link></li>
                                                            </>
                                                        })
                                                        :
                                                        <li> <a>No Record found!</a></li>
                                                }
                                            </ul>
                                        </div>
                                    </div>
                                </form>
                            </li> */}
                            <li className="nav-item ms-xl-auto">
                                <Link className="nav-link nav-link-bg-gray" to={"/business/login"} >For Business</Link>
                            </li>
                            <div id="dropdown2" className="btn-group pro_drop d-xl-block d-none">
                                <img onClick={() => {changeUserProfile(); hideOnClickOutside('dropdown2')}} src={getProfilePictureUri(userData?.profile_image, "../../../assets")} alt="DoubleSpot.com" data-bs-toggle="dropdown"
                                    aria-expanded="true" className="nav_user_img rounded-circle" />

                                <div className={openUserProfile ? "dropdown-menu dropdown-menu-end show" : "dropdown-menu dropdown-menu-end"}>
                                    <Link onClick={() => changeUserProfile()} to={'/profile'}><a className="text_icon text-decoration-none text-black">
                                        <img src="../../../assets/icons/profile.png" alt="DoubleSpot.com" /> Profile
                                    </a></Link>
                                    {/* <Link to={'/chat'} onClick={() => changeUserProfile()}><a className="text_icon text-decoration-none text-black">
                                        <img src="../../../assets/images/messages.png" alt="DoubleSpot.com" /> Messages
                                    </a></Link> */}
                                    {/* <Link to={'/notification'} onClick={() => changeUserProfile()}><a className="text_icon text-decoration-none text-black">
                                        <img src="../../../assets/images/ball.png" alt="DoubleSpot.com" /> Notifications
                                    </a></Link> */}
                                    <Link to={'/booking'} onClick={() => changeUserProfile()}><a className="text_icon text-decoration-none text-black">
                                        <img src="../../../assets/icons/bookings.png" alt="DoubleSpot.com" /> Bookings
                                    </a></Link>
                                    <Link to={'/wishlist'} onClick={() => changeUserProfile()}><a className="text_icon text-decoration-none text-black">
                                        <img src="../../../assets/icons/wishlist.png" alt="DoubleSpot.com" /> Wishlists
                                    </a></Link>
                                    <Link to={'/profile'} onClick={() => changeUserProfile()}><a href="help.html" className="pro_btn d-none bg-danger">Help Center</a></Link>
                                    <a className="pro_btn" style={{cursor: "pointer"}} onClick={() => logoutUser()} >Logout</a>

                                </div>
                            </div>

                        </ul>
                    </div>
                </div>
            </nav>
            {/* notification */}
            <>
                <div className={showNotifyUser ? "dropdown-menu notifiy show" : "dropdown-menu notifiy "}>
                    <div className="title pb-2" onClick={() => showUserNotification()}>
                        <img src="../../../assets/images/ball2.png" alt="" /> Notifications ()
                    </div>
                    <ul className="list-unstyled d-grid ">
                        <li>
                            <div className="d-flex bd-highlight align-items-start gap-md-3  gap-2">
                                <div className="img_cont">
                                    <img src="./images/ball_img.png" className="rounded-circle user_img" />
                                    <span className="online_icon d-none"></span>
                                </div>
                                <div className="user_info pt-md-2">
                                    <span>johnmarker1589</span>
                                    <p className="mb-0">Lorem Ipsum is simply dummy text of the printing
                                        and type.</p>
                                    <small className="text-muted fs-md-6">1 Minute ago</small>
                                </div>
                            </div>
                        </li>
                        <li>
                            <div className="d-flex bd-highlight align-items-start gap-md-3  gap-2">
                                <div className="img_cont">
                                    <img src="./images/ball_img.png" className="rounded-circle user_img" />
                                    <span className="online_icon d-none"></span>
                                </div>
                                <div className="user_info pt-md-2">
                                    <span>johnmarker1589</span>
                                    <p className="mb-0">Lorem Ipsum is simply dummy text of the printing
                                        and type.</p>
                                    <small className="text-muted fs-md-6">1 Minute ago</small>
                                </div>
                            </div>
                        </li>
                        <li>
                            <div className="d-flex bd-highlight align-items-start gap-md-3  gap-2">
                                <div className="img_cont">
                                    <img src="./images/ball_img.png" className="rounded-circle user_img" />
                                    <span className="online_icon d-none"></span>
                                </div>
                                <div className="user_info pt-md-2">
                                    <span>johnmarker1589</span>
                                    <p className="mb-0">Lorem Ipsum is simply dummy text of the printing
                                        and type.</p>
                                    <small className="text-muted fs-md-6">1 Minute ago</small>
                                </div>
                            </div>
                        </li>
                        <li>
                            <div className="d-flex bd-highlight align-items-start gap-md-3  gap-2">
                                <div className="img_cont">
                                    <img src="./images/ball_img.png" className="rounded-circle user_img" />
                                    <span className="online_icon d-none"></span>
                                </div>
                                <div className="user_info pt-md-2">
                                    <span>johnmarker1589</span>
                                    <p className="mb-0">Lorem Ipsum is simply dummy text of the printing
                                        and type.</p>
                                    <small className="text-muted fs-md-6">1 Minute ago</small>
                                </div>
                            </div>
                        </li>
                        <li>
                            <div className="d-flex bd-highlight align-items-start gap-md-3  gap-2">
                                <div className="img_cont">
                                    <img src="./images/ball_img.png" className="rounded-circle user_img" />
                                    <span className="online_icon d-none"></span>
                                </div>
                                <div className="user_info pt-md-2">
                                    <span>johnmarker1589</span>
                                    <p className="mb-0">Lorem Ipsum is simply dummy text of the printing
                                        and type.</p>
                                    <small className="text-muted fs-md-6">1 Minute ago</small>
                                </div>
                            </div>
                        </li>
                        <li className="text-end">
                            <a href="notification.html" className="see_all">See all</a>
                        </li>
                    </ul>
                </div>
            </>
        </>
    }

    return <>
        <nav className="navbar  navbar-expand-xl bg-white" onMouseLeave={() => handleSeachBarfalse()}>
            <div className="container-fluid flex-row flex-nowrap">
                <a className="navbar-brand" ><img src='../../../assets/images/logo.png' alt="doublespot.com " /></a>
                <button className="navbar-toggler" type="button" onClick={() => handleNavbar()}>
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className={openMinNavBar ? "collapse navbar-collapse show" : "collapse navbar-collapse"} id="navbarSupportedContent">
                    <ul className="navbar-nav w-100 mb-lg-0 align-itmes-center">
                        {/* <li className="nav-item d_filter">
                            <form className="dropdown_filter ">
                                <div className="dropdown filter">
                                    <div className="form-group dropbtn dropdown-open">
                                        <i className="fa-solid fa-magnifying-glass"></i>
                                        <input type="button" onMouseEnter={() => handleSeachBar()} className="form-control" value="Search" />
                                    </div>
                                    <div id="myDropdown" className="dropdown-content dropdown-inner" style={{ display: showSearchBar ? "block" : "none" }}>
                                        <div className="form-group">
                                            <i className="fa-solid fa-magnifying-glass dropdown-open"
                                            ></i>
                                            <input type="text" onChange={(e) => seachData(e.target.value)} className="form-control w-100 rounded-0"
                                                placeholder="Find a venues" id="myInput" />
                                        </div>
                                        <ul className=" inner_links list-unstyled" id="myUL">
                                            {
                                                searhVenue && searhVenue.length > 0 ?

                                                    searhVenue.map((val, key) => {
                                                        return <>

                                                            <li key={key} onClick={() => localStorage.setItem("venueId", val.id)} ><Link to={'/venue'}>{val.name}</Link></li>
                                                        </>
                                                    })
                                                    :
                                                    <li> <a>No Record found!</a></li>

                                            }
                                        </ul>
                                    </div>
                                </div>
                            </form>
                        </li> */}
                        <li className="nav-item ms-xl-auto">
                            <Link className="nav-link nav-link-bg-gray" to={"/business/login"} >For Business</Link>
                        </li>
                        {/* //signUp modal */}
                        <SignUp/>
                        {/* //singIN modal */}
                        <SignIn btn="navbar"/>

                        <ForgotPassword/>
                    </ul>
                </div>
            </div>
        </nav>
    </>
}


export default Navbar;
